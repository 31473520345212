import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { BiSearch } from "react-icons/bi"; // Assuming you're using react-icons

const NewInput = ({
  addons,
  search,
  placeholder,
  bsSize,
  innerStyle,
  ...props
}) => {
  if (!addons && !search) {
    return (
      <Input
        placeholder={placeholder}
        bsSize={bsSize}
        className={innerStyle}
        {...props}
      />
    );
  }

  return (
    <InputGroup
      size={bsSize}
      className={"input-group-merge tw-flex-nowrap " + innerStyle}>
      {search && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <BiSearch
              size={bsSize === "sm" ? 16 : 20}
              className="tw-text-secondary-base"
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
      <Input
        placeholder={placeholder ? placeholder : search ? "Search" : ""}
        {...props}
      />
      {addons}
    </InputGroup>
  );
};

export default NewInput;
