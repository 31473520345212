import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layoutedNodes: null,
  monitoringId: null,
  selectedNamespaces: null,
  selectedContainer: null,
  isLogTextWrapped: false,
  selectedLogStatus: null
};
export const serviceMapNodesSlice = createSlice({
  name: "serviceMapNodesSlice",
  initialState,
  reducers: {
    setLayoutedNodesData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.layoutedNodes = action.payload;
    },
    setMonitoringId: (state, action) => {
      state.monitoringId = action.payload;
    },
    setSelectedNamespacesToRedux: (state, action) => {
      state.selectedNamespaces = action.payload;
    },
    setSelectedContainerToRedux: (state, action) => {
      state.selectedContainer = { ...action.payload };
    },
    setIsLogTextWrapped: (state, action) => {
      state.isLogTextWrapped = action.payload;
    },
    setSelectedLogStatusToRedux: (state, action) => {
      state.selectedLogStatus = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setLayoutedNodesData,
  setMonitoringId,
  setSelectedNamespacesToRedux,
  setSelectedContainerToRedux,
  setIsLogTextWrapped,
  setSelectedLogStatusToRedux
} = serviceMapNodesSlice.actions;

export default serviceMapNodesSlice.reducer;
