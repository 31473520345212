// ** Initial State
const initialState = {
  isZombieFilterActive: false,
  isShowUnknownConnections: false,
  isRPSFilterActive: false,
  isLatencyFilterActive: false,
  isStatusFilterActive: false,
  isProtocolFilterActive: false,
  latencyFilter: [],
  rpsFilter: [],
  searchedNodes: {},
  hoveredNode: undefined,
  httpStatuses: [1, 2, 3, 4, 5],
  protocols: ["POSTGRES", "AMQP", "HTTP", "HTTPS", "REDIS", "GRPC"],
  allProtocols: ["HTTP", "HTTPS", "POSTGRES", "AMQP", "REDIS", "GRPC"],
  allHttpStatuses: [1, 2, 3, 4, 5],
  distTracingFilter: false,
  monitoringActiveTab: "0"
};

const ServiceMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER_DATA":
      return {
        ...state,
        ...action.value
      };
    case "SET_SEARCHED_NODES":
      return {
        ...state,
        searchedNodes: action.value
      };
    case "SET_HOVERED_NODE":
      return {
        ...state,
        hoveredNode: action.value
      };
    case "RESET_FILTERS":
      return {
        ...state,
        ...initialState
      };
    case "DIST_TRACING_FILTER":
      return {
        ...state,
        distTracingFilter: action.value
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        monitoringActiveTab: action.value
      };
    default:
      return state;
  }
};

export default ServiceMapReducer;
