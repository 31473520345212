import useJwt from "@src/auth/jwt/useJwt";
import baseQueryConfig from "@src/redux/storeConfig/baseQueryConfig";
import { handleLogout } from "@store/actions/auth";
import { Mutex } from "async-mutex";
// create a new mutex not to send refresh tokens requests for all failing requests
const mutex = new Mutex();
const baseQuery = baseQueryConfig;
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    //request sent but log outed immediately
    if (window.location.pathname === "/login") {
      return;
    }
    // checking whether the mutex is locked
    if (!mutex.isLocked() && !useJwt.getAlreadyFetchingAccessToken()) {
      const release = await mutex.acquire();
      useJwt.setAlreadyFetchingAccessToken(true);
      try {
        const refreshResult = await baseQuery(
          {
            url: useJwt.jwtConfig.refreshEndpoint,
            method: "POST",
            // TODO: get it from the authTokenSlice
            body: { refresh: useJwt.getRefreshToken() }
          },
          api,
          extraOptions
        );
        if (
          refreshResult?.data &&
          refreshResult?.data?.refresh &&
          refreshResult?.data?.access
        ) {
          useJwt.setAlreadyFetchingAccessToken(false);
          // TODO: set them from the authTokenSlice
          //   api.dispatch(tokenReceived(refreshResult.data));
          useJwt.setRefreshToken(JSON.stringify(refreshResult.data.refresh));
          useJwt.setToken(JSON.stringify(refreshResult.data.access));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          useJwt.setAlreadyFetchingAccessToken(false);
          api.dispatch(handleLogout());
          window.location.href = "/login";
        }
      } catch (err) {
        useJwt.setAlreadyFetchingAccessToken(false);
        api.dispatch(handleLogout());
        window.location.href = "/login";
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default baseQueryWithReauth;
